import React from 'react';

import { actionListToURL, CLONE_ACTION, DELETE_ACTION, EDIT_ACTION, MENU_LIST, VIEW_ACTION } from '../../constants/keyConstants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { ClickAwayListener, Grow, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Popper, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';

import './ActionCell.scss';

const ActionCell = (props) => {
  const navigate = useNavigate();
  const { actionList, editActionURL, record, actionHandlers } = props;
  const id = record?.taskId ? record?.taskId : record.id;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);



  return (
    <div className="actionBtns" >
      <Stack direction="row" spacing={1}>
        {actionList?.includes(VIEW_ACTION) && (<IconButton aria-label='View' title='View' size='small' onClick={() => {
          actionHandlers?.rowViewHandler(record.id)
        }}>
          <VisibilityOutlinedIcon fontSize="inherit" />
        </IconButton>)}
        {actionList?.includes(EDIT_ACTION) && (<IconButton aria-label='Edit' title='Edit' size='small' onClick={() => {
          actionHandlers?.editRowHandler ? actionHandlers?.editRowHandler(record.id) : navigate(`${editActionURL}/${id}`)
        }}>
          <ModeEditOutlineOutlinedIcon fontSize="inherit" />
        </IconButton>)}
        {actionList?.includes(DELETE_ACTION) && (<IconButton aria-label='Delete' title='Delete' size='small' onClick={() => {
          actionHandlers?.rowDeleteHandler(record.id)
        }}>
          <DeleteOutlineOutlinedIcon fontSize="inherit" />
        </IconButton>)}
        {actionList?.includes(CLONE_ACTION) && (<IconButton aria-label='Clone' title='Clone' size='small' onClick={() => {
          actionHandlers?.rowCloneHandler(record.id)
        }}>
          <ContentCopyOutlinedIcon fontSize="inherit" />
        </IconButton>)}
        {actionList?.includes(MENU_LIST) && (<div className='addMenu' >
          <IconButton style={{ minWidth: '20px', padding: '0px', margin: '0px' }}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreVertIcon fontSize="inherit" />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      onMouseLeave={handleClose}
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {actionHandlers?.actionList?.map((listItem)=><MenuItem onClick={() => {
                        navigate(`${actionListToURL[listItem]}?${id}`)
                      }}>
                        <ListItemIcon> <AddIcon fontSize="small" /> </ListItemIcon>
                        {listItem}
                      </MenuItem>)}
                      {/* <MenuItem onClick={() => {
                        navigate(`/worx/task/add?${id}`)
                      }}>
                        <ListItemIcon> <AddIcon fontSize="small" /> </ListItemIcon>
                        Add Task
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon> <AddIcon fontSize="small" /> </ListItemIcon>
                        Add Sub Task
                      </MenuItem> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>)}
      </Stack>
    </div>
  )
}

export default ActionCell