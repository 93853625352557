import React, { useEffect, useState } from "react";

import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import ProductCategory from "../AddProductTabs/ProductCategory";
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductMasterImageDetails,
  handleEditorText,
  updateProductDetails,
} from '../../../../features/table/tableSlice';
import Notifications from '../../../../components/Notifications/Notifications';
import axios from 'axios'

import DescriptionIcon from '@mui/icons-material/TextSnippetOutlined';
import ProductImageIcon from '@mui/icons-material/PhotoOutlined';
import OrganizeIcon from '@mui/icons-material/TopicOutlined';

import { useLocation, useNavigate } from 'react-router-dom';
import CustomizationWrapper from '../../../../common/CustomizationWrapper/CustomizationWrapper';
import { __API_BASEURL__ } from '../../../../constants/keyConstants';
import TextEditor from '../../../../components/TextEditor/TextEditor';
import "./AddServices.scss";
import FileUploadWrapper from "../../../../features/FileUpload/FileUploadWrapper";
import UploadImageDialog from "../../../../components/UploadImageDialog/UploadImageDialog";
import PhotosArea from "../../../../components/UploadImageDialog/PhotosArea";

export default function AddServices({
  productMasterDetails = {},
  addProductBtnClick,
  productDetails = {},
  setProductDetails = () => { },
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(false)
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const { categoryList, subCategoryList, classOfProductList } = useSelector(state => state.table)

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const location = useLocation()
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    localStorage.setItem("service_currentTab", event.target.innerText)
    navigate(`${location.pathname}?${event.target.innerText}`)
    setValue(newValue);
  };
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    color: "success",
  });
  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");

  const onUpload = (formDataList) => {
    setImageLoading(true)
    const formData = new FormData();

    formDataList.forEach(file => {
      formData.append('images[]', file); // Append each file to the FormData
    });
    formData.append('companyId', company_id);
    formData.append('userId', user_id);
    formData.append("object_name", "service")
    formData.append("uploadFrom", "service")

    axios.post(`${__API_BASEURL__}/module/uploads/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      }
    }).then(function (res) {
      if (res.data.status == 200) {
        if (res.data.result) {
          const requestData = {
            "companyId": company_id,
            "userId": user_id,
            ...productDetails,
            productId: productDetails?.id,
            imageUrl: [...productMasterDetails?.imageUrl, ...res.data.result]
          }
          dispatch(updateProductDetails({ endpointURL: `/master/product/update?`, payload: requestData }))?.then((imageRes) => {
            if (imageRes.payload.data.success) {
              setTimeout(() => {
                dispatch(fetchProductMasterImageDetails(`/master/product/details?company_id=${company_id}&user_id=${user_id}&product_id=${productDetails?.id}&type=service`)).then((detailResponse) => {
                  setImageLoading(false)
                })
                setNotification({
                  open: true,
                  message: `Uploaded Successfully!!`,
                  color: "success",
                })
              }, 1000)
            }
          })

        }
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: `Something went Wrong while adding files`,
          color: "error",
        })
      });
  }


  useEffect(() => {
    let path = location.search || localStorage.getItem("service_currentTab");
    if (path?.includes("Description")) setValue(0);
    else if (path?.includes("Images")) setValue(1);
    else if (path?.includes("Organise")) setValue(2);
  }, [])

  const onCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }

  return (
    <div >
      {openImageDialog && <UploadImageDialog linkObject={{ "service": productMasterDetails?.id }} open={openImageDialog} onClose={() => setOpenImageDialog(!openImageDialog)} />}
      {notification?.open && <Notifications {...notification} onClose={onCloseNotification} />}
      <div className=" white-container-auto mb-2">
        <div className="row py-2">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className=""><strong>Service Name/Number</strong> : <i>{productMasterDetails?.name}</i></div>
              <div className="ml-auto "><span className="align-items-end"><Button variant="outlined" disabled> {productMasterDetails?.status}</Button></span></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" white-container-auto product-height">
        <div className="row">
          <div className="col-12 productTabs">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="services tabs"
                variant="scrollable"
                scrollButtons={false}
              >
                <Tab icon={<DescriptionIcon fontSize="small" />} iconPosition="start" label="Description" {...a11yProps(0)} />
                <Tab icon={<ProductImageIcon fontSize="small" />} iconPosition="start" label="Images" {...a11yProps(1)} />
                {/* <Tab icon={<InventoryIcon fontSize="small" />} iconPosition="start" label="Inventory" {...a11yProps(2)} /> */}
                <Tab icon={<OrganizeIcon fontSize="small" />} iconPosition="start" label="Organise" {...a11yProps(2)} />
                {/* <Tab icon={<ProductCustomizationIcon fontSize="small" />} iconPosition="start" label="Product Customization" {...a11yProps(3)} /> */}
                {/* <Tab icon={<QuickBooksIcon fontSize="small" className="custom-icon" />} iconPosition="start" label="quickbooks" {...a11yProps(5)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {/* <ProductDescription productDetails={productDetails} setProductDetails={setProductDetails} /> */}

              <TextEditor
                input={productMasterDetails?.description}
                onChangeCallback={(editorText) => dispatch(handleEditorText({
                  description: editorText
                }))}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {/* <Location {...productMasterDetails} /> */}
              {/* Product Img  */}
              {/* <FileUpload onUpload={onUpload} /> */}
              {/* <FileUploadWrapper imageDetails={productMasterDetails?.imageUrl} onUpload={onUpload} imageLoading={imageLoading} /> */}
              <PhotosArea
                onClickHandler={() => setOpenImageDialog(true)}
                id={productMasterDetails?.id}
                setNotification={setNotification}
                showAddButton={true}
                objectName='service'
                fullWidth={true}
              />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2}>
              <Inventory />
            </CustomTabPanel> */}
            {/* <CustomTabPanel value={value} index={2}>
                  <Shipping {...productMasterDetails} />
              </CustomTabPanel> */}
            <CustomTabPanel value={value} index={2}>
              <ProductCategory categoryList={categoryList}
                setProductDetails={setProductDetails}
                productDetails={productDetails}
                subCategoryList={subCategoryList}
                classOfProductList={classOfProductList}
                productMasterDetails={productMasterDetails}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <CustomizationWrapper categoryList={categoryList || []} />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={5}>
              <QuickBooks {...productMasterDetails} />
            </CustomTabPanel> */}
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 d-flex  justify-content-end align-items-center gap-2 text-end pt-2"> */}
      <Stack direction="row" gap={2} justifyContent="end" className="py-2" >
        <Button variant="outlined" onClick={() => navigate(-1)} >Cancel</Button>
        <Button variant="outlined" onClick={() => addProductBtnClick(productMasterDetails?.id)}>Save as Draft</Button>
        <Button variant="contained" onClick={() => addProductBtnClick(productMasterDetails?.id)}>Publish Service</Button>
      </Stack>
      {/* </div>
      </div> */}
    </div>
  );
}
