import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectMenu from "../../components/SelectMenu/SelectMenu";

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import estimateHomeIcon from '../../assets/images/img-add-customer.png';
import EmailTextField from "../../components/EmailTextField/EmailTextField";
import Notifications from '../../components/Notifications/Notifications';
import PhoneTextField from "../../components/PhoneTextField/PhoneTextField";
import { fetchContactCompanyOwnersList, fetchContactDetails, fetchLeadStatusDropdown, postCreateContact } from '../../features/table/tableSlice';
import './Contact.scss';

export default function Contact() {
  const { companyOwnerdropDownData, leadDropdown } = useSelector(state => state.table)
  
  const [isLeftPanelVisible, setLeftPanelVisibility] = useState(true);
  const [contactDetails, setContactDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    displayName: '',
    companyName: '',
    phonePrefix: '',
    contactOwner: '',
    contactStage: '',
    contactStatus: '',
  });
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    color: "success",
  });
  const [displayNameList, setDisplayNameList] = useState([]);
  const [phonePrefixList, setPhonePrefixList] = useState([]);
 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");

  const addContactBtnClick = () => {
    const { displayName, firstName, lastName, email, phone, contactOwner, phonePrefix, companyName, contactStage, contactStatus } = contactDetails;
    const requestData = {
      "companyId": company_id,
      "userId": user_id,
      firstName,
      lastName,
      email,
      phone,
      displayName,
      companyName,
      phonePrefix,
      contactOwner,
      contactStage,
      contactStatus
    }
    const response = dispatch(postCreateContact({ endpointURL: `/master/contact/create?`, payload: requestData }))
    response.then(function (res) {
      if (res?.payload?.data) {
        setNotification({
          open: true,
          message: `${res.payload?.data?.message} for ${res.payload?.data?.result.contactId}`,
          color: "success",
        })
        if (res.payload?.data?.result?.contactId) {
          dispatch(fetchContactDetails(`/master/contact/details?company_id=${company_id}&user_id=${user_id}&contact_id=${res.payload.data?.result.contactId}`))
          navigate(`/contact/edit/${res.payload.data.result.contactId}`);
        }
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went Wrong while creating contact',
          color: "error",
        })
      });

  };

  useEffect(() => {
    dispatch(fetchContactCompanyOwnersList(`/master/employee/dropdown?company_id=${company_id}&user_id=${user_id}`))
    dispatch(fetchLeadStatusDropdown(`/settings/option/dropdown?companyId=${company_id}&userId=${user_id}&objectName=contact`))
    axios.get('https://api.worxstream.io/seeds')?.then((response) => {
      setPhonePrefixList(response?.data?.result?.map((codeList) => ({ label: codeList.country_name , value: codeList.country_code })))
    })
  }, [])

  useEffect(() => {
    let list = [];
    if (contactDetails.companyName) {
      list.push({
        label: contactDetails.companyName,
        value: contactDetails.companyName,
      })
    }
    if (contactDetails.firstName && contactDetails.lastName) {
      list.push({
        label: `${contactDetails.firstName} ${contactDetails.lastName}`,
        value: `${contactDetails.firstName} ${contactDetails.lastName}`,
      })
      list.push({
        label: `${contactDetails.lastName} ${contactDetails.firstName}`,
        value: `${contactDetails.lastName} ${contactDetails.firstName}`,
      })
    }
    if (contactDetails.firstName && !contactDetails.lastName) {
      list.push({
        label: contactDetails.firstName,
        value: contactDetails.firstName,
      })
    }
    if (!contactDetails.firstName && contactDetails.lastName) {
      list.push({
        label: contactDetails.lastName,
        value: contactDetails.lastName,
      })
    }
    setDisplayNameList(list)
  }, [contactDetails])

  const onCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }
  return (
    <div>
      {notification?.open && <Notifications {...notification} onClose={onCloseNotification}/>}
      <div className="container-fluid contact">
        <div className="d-flex gap-3 position-relative">
          {/* <IconButton aria-label="Collapse" className={`expandCollapseBtn ${isLeftPanelVisible ? ' ' : 'moveLeft'}`} onClick={toggleLeftPanel}>
          <img src={`${isLeftPanelVisible ? expandCollapseIcon : expandCollapseRightIcon}`} alt='' />
        </IconButton> */}
          <div className={`leftPanel ${isLeftPanelVisible ? "visiblePanel " : "hiddenPanel"}`} >
            <div className="white-container">
              <div className="row leftContent">
                <div className='col-12'>
                  <p className='font-medium mb-0'>Add new Contact</p>
                </div>
                <div className="col-12">
                  <TextField label="Company Name" name="companyName" variant="standard" fullWidth onChange={(event) => {
                    setContactDetails({ ...contactDetails, companyName: event.target.value })
                  }} />
                </div>
                <div className="col-12 col-lg-6">
                  <TextField label="First Name" name="firstName" variant="standard" fullWidth onChange={(event) => {
                    setContactDetails({ ...contactDetails, firstName: event.target.value })
                  }} />
                </div>
                <div className="col-12 col-lg-6">
                  <TextField label="Last Name" name="lastName" variant="standard" fullWidth onChange={(event) => setContactDetails({ ...contactDetails, lastName: event.target.value })} />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Display Name" options={displayNameList}
                    disabled={displayNameList.length === 0}
                    value={contactDetails?.displayName}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, displayName: selectedValue })}
                  />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Contact Owner"
                    options={companyOwnerdropDownData?.map((owner) => ({ ...owner, label: owner?.label, value: owner?.id }))}
                    value={contactDetails?.contactOwner}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, contactOwner: companyOwnerdropDownData?.find((contact) => contact?.employeeId === selectedValue)?.employeeId })}
                  />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Lifecycle Stage" 
                    options={leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId }))}
                    onChangeCallback={(selectedValue) => {
                      setContactDetails({ ...contactDetails, contactStage: selectedValue })}
                    }
                    />
                </div>
                <div className="col-12">
                  <SelectMenu selectLabel="Select Lead Status"
                    options={leadDropdown && leadDropdown[1]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId }))}
                    onChangeCallback={(selectedValue) => {
                      setContactDetails({ ...contactDetails, contactStatus: selectedValue })}
                    }
                  />
                </div>
                <div className="col-12 ">
                  <EmailTextField label="Email" value={contactDetails?.email} fullWidth onChange={(value) => setContactDetails({ ...contactDetails, email: value })} />
                </div>
                <div className="col-12 col-lg-4">
                  <SelectMenu selectLabel="Select Country Code" options={phonePrefixList}
                    value={contactDetails?.phonePrefix}
                    onChangeCallback={(selectedValue) => setContactDetails({ ...contactDetails, phonePrefix: selectedValue })}
                  />
                </div>
                <div className="col-12 col-lg-8">
                  <PhoneTextField label="Phone" fullWidth onChange={(event) => setContactDetails({ ...contactDetails, phone: event.target.value })} />
                </div>
                <div className='col-12'>
                  <div className='d-flex gap-3 pt-3'>
                    <Button variant='outlined' onClick={() => setContactDetails({})}>Reset</Button>
                    <Button variant='contained' onClick={addContactBtnClick} >Add Contact</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* RIGHT CONTAINER */}
          <div className={`rightPanel ${isLeftPanelVisible ? " " : "rp100"}`}>
            <div className=" white-container">
              <div className="row p-5">
                <div className="col-12 p-5">
                  <img src={estimateHomeIcon} alt='' />
                  <h4 className="ps-5">
                    Add new contact by entering essential details in the left
                    panel.
                  </h4>
                  <p className="ps-5">
                    Once contact is added, you can further input additional
                    information such as addresses, estimates, invoices details,
                    and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
