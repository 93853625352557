import React, { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Paper,
  Stack,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  ViewModuleOutlined as ViewModuleIcon,
  ViewListOutlined as ViewListIcon,
  FolderOutlined as FolderIcon,
  InsertDriveFileOutlined as FileIcon,
  CloudDownloadOutlined,
} from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import FileUpload from "./FileUpload";
import "./FileUploadWrapper.scss";
import LoadingComponent from "../../common/LoadingComponent/LoadingComponent";
import AutocompleteSelect from "../../components/AutocompleteSelect/AutocompleteSelect";
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import { isValidImage } from '../../common/utils';

function FileUploadWrapper({
  onUpload = () => { },
  imageLoading,
  imageDetails = [],
  setSelectedFiles,
  selectedFiles,
  lastImageElementRef,
  downloadFile = () => { },
  onDelete = () => { },
}) {
  const [view, setView] = useState("grid");
  const [type, setType] = useState("file");
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([
    { id: 1, name: "Folder One" },
    { id: 2, name: "Folder Two" },
  ]);

  useEffect(() => {
    setFiles(
      imageDetails.map((eachUrl, index) => ({
        ...eachUrl,
        name: eachUrl?.fileName ? eachUrl?.fileName : `File ${index + 1}`,
        url: eachUrl?.url ? eachUrl?.url : eachUrl,
        id: eachUrl?.id ? eachUrl?.id : index + 1,
      }))
    );
  }, [imageDetails]);

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

  const handleTypeChange = (event, nextType) => {
    setSelectedFiles([])
    setType(nextType);
  };

  const handleDelete = (data, isFile) => {
    if (isFile) {
      // setFiles(files.filter((file) => file.id !== data?.id));
      onDelete([data?.id], data?.name);
    } else {
      // setFolders(folders.filter((folder) => folder.id !== data?.id));
    }
  };

  const fileTypeOptions = [
    { label: "Images", value: 1 },
    { label: "Documents", value: 2 },
  ];

  const fileSizeOptions = [
    { label: "> 2mb", value: 1 },
    { label: "2mb - 5mb", value: 2 },
    { label: "< 5mb", value: 3 },
  ];

  const fileUsedInOptions = [
    { label: "Product", value: 1 },
    { label: "Deal", value: 2 },
    { label: "Estimate", value: 3 },
  ];

  const selectFolderOptions = [
    { label: "Product", value: 1 },
    { label: "Deal", value: 2 },
    { label: "Estimate", value: 3 },
  ];

  const [selectedImage, setSelectedImage] = useState(null);
    
  const checkPreviewFile = (file) => {
    return (
      <div>
        {isValidImage(file.fileName) ? (
          <img src={file.url} alt={file.fileName} />
        ) : (
          <SdCardAlertIcon />
        )}
      </div>
    );
  };
  
  const handleImageClick = (file) => {
    setSelectedImage(file);
  };

  const handleCloseImageDialog = () => {
    setSelectedImage(null);
  };

  const renderFileItem = (file, index, isListView) => {
    const itemContent = (
      <>
        <div className={isListView ? "d-flex align-items-center" : "gridBox"}>
          <Stack direction="row" alignItems="center" gap={0}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() =>
                    setSelectedFiles((prev) =>
                      prev.includes(file.id)
                        ? prev.filter((id) => id !== file.id)
                        : [...prev, prev?.includes(file.id) ? null : file.id]
                    )
                  }
                  checked={selectedFiles?.includes(file.id)}
                />
              }
            />
            <Typography title={file.name} className="imgName">
              {file.name}
            </Typography>
          </Stack>
          {!isListView && (
            <div className="imgHolder">
              <div className="file-atc-box">
                <div
                  className="file-image"
                  onClick={() => handleImageClick(file)}
                >
                  {/* <img src={file?.url} alt={file?.url} /> */}
                  {checkPreviewFile(file)}
                </div>
              </div>
            </div>
          )}
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            paddingRight={1}
          >
            <IconButton edge="end" aria-label="download" onClick={() => downloadFile(file)}>
              <DownloadIcon fontSize="small" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDelete(file, true)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        </div>
      </>
    );

    return isListView ? (
      <div
        key={file.id}
        ref={index === files.length - 1 ? lastImageElementRef : null}
        className="image-item"
      >
        <Paper
          key={file.id}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
            mb: 1,
          }}
        >
          <div className="d-flex align-items-center">
            <FormControlLabel control={<Checkbox onChange={() => setSelectedFiles((prev) => [...prev, prev?.includes(file.id) ? null : file.id])} checked={selectedFiles?.includes(file.id)} />} />
            <FileIcon sx={{ mr: 2 }} />
            <Typography variant="body1" sx={{ flex: 1 }}>
              {file.name}
            </Typography>
          </div>
          <div className="d-flex align-items-center gap-2">
            <IconButton edge="end" aria-label="edit">
              <CloudDownloadOutlined fontSize="small" onClick={()=>downloadFile(file)}/>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDelete(file, true)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </Paper>
      </div>
    ) : (
      <Grid
        item
        xs={6}
        sm={3}
        md={2}
        key={file.id}
        ref={index === files.length - 1 ? lastImageElementRef : null}
      >
        {itemContent}
      </Grid>
    );
  };

  return (
    <Box p={2}>
      <FileUpload onUpload={onUpload} imageLoading={imageLoading} />

      <div className='d-flex justify-content-space-between'>
        <div className="d-block d-md-flex align-items-center gap-3 w-100">
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleTypeChange}
            aria-label="file or folder"
            className='mb-2 mb-md-0'
          >
            <ToggleButton value="file" aria-label="files" title='Files'>
              <FileIcon fontSize='small' className='me-2' /> Files
            </ToggleButton>
            <ToggleButton value="folder" aria-label="folders" title='Folder'>
              <FolderIcon fontSize='small' className='me-2' /> Folders
            </ToggleButton>
          </ToggleButtonGroup>

          <AutocompleteSelect options={fileTypeOptions} value={fileTypeOptions[0]} label="File Type" onChange={() => { }} />
          <AutocompleteSelect options={fileSizeOptions} value={fileSizeOptions[0]} label="File Size" onChange={() => { }} />
          <AutocompleteSelect options={fileUsedInOptions} value={fileUsedInOptions[0]} label="Used in" onChange={() => { }} />
          <AutocompleteSelect options={selectFolderOptions} value={selectFolderOptions[0]} label="Select Folder" onChange={() => { }} />
        </div>
        <div>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view mode"
            sx={{ ml: 2 }}
          >
            <ToggleButton value="list" aria-label="list view" title='List View'>
              <ViewListIcon fontSize='small' />
            </ToggleButton>
            <ToggleButton value="grid" aria-label="grid view" title='Grid View'>
              <ViewModuleIcon fontSize='small' />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      {imageLoading ? (
        <LoadingComponent />
      ) : (
        <Box mt={2}>
          {view === "list" ? (
            <Box>
              {type === "file" ?
                files.map((file, index) => renderFileItem(file, index, true))
                : folders.map((folder) => (
                  <Paper
                    key={folder.id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 1,
                      mb: 1,
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <FormControlLabel control={<Checkbox onChange={() => setSelectedFiles((prev) => [...prev, prev?.includes(folder.id) ? null : folder.id])} />} checked={selectedFiles?.includes(folder.id)} />
                      <FolderIcon sx={{ mr: 2 }} />
                      <Typography variant="body1" sx={{ flex: 1 }}>
                        {folder.name}
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <IconButton edge="end" aria-label="edit">
                        <CloudDownloadOutlined fontSize="small" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(folder, false)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Paper>
                ))}
            </Box>
          ) : (
            <Grid container spacing={2}>
              {type === "file"
                ? files.map((file, index) => renderFileItem(file, index, false))
                : folders.map((folder) => (
                  <Grid item xs={6} sm={3} md={2} key={folder.id}>
                    <div className="gridBox">
                      <Stack direction="row" alignItems="center" gap={0}>
                        <FormControlLabel control={<Checkbox onChange={() => setSelectedFiles((prev) => [...prev, prev?.includes(folder.id) ? null : folder.id])} checked={selectedFiles?.includes(folder.id)} />} />
                        <Typography className="imgName">
                          {folder.name}
                        </Typography>
                      </Stack>
                      <div className="imgHolder">
                        <FolderIcon sx={{ fontSize: 70, color: "#d7d7d7" }} />
                      </div>
                      <Stack
                        direction="row"
                        gap={1}
                        justifyContent="flex-end"
                        paddingRight={1}
                      >
                        <IconButton edge="end" aria-label="edit">
                          <CloudDownloadOutlined fontSize="small" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDelete(folder.id, true)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    </div>
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      )}

      <Dialog
        open={!!selectedImage}
        onClose={handleCloseImageDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {selectedImage?.name}
          <IconButton
            aria-label="close"
            onClick={handleCloseImageDialog}
            sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedImage && (
            <img
              src={selectedImage.url}
              alt={selectedImage.name}
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default FileUploadWrapper;
