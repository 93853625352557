// import React, { useEffect, useState } from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import './FileUpload.scss'
// import LoadingComponent from '../../../common/LoadingComponent/LoadingComponent';
// import { useSelector } from 'react-redux';

// function FileUpload({ onUpload }) {
//     const { productMasterImageDetails, productMasterImageDetailsLoading } = useSelector(state => state.table)
//     const [isLoading, setIsLoading] = useState(productMasterImageDetailsLoading)
//     const [selectedfile, SetSelectedFile] = useState([]);
//     const [Files, SetFiles] = useState(productMasterImageDetails || []);
//     const [formDataList, setFormDataList] = useState();
//     const [error, setError] = useState('');

//     // Allowed file types
//     const allowedTypes = ['image/jpeg', 'image/png'];

//     const filesizes = (bytes, decimals = 2) => {
//         if (bytes === 0) return '0 Bytes';
//         const k = 1024;
//         const dm = decimals < 0 ? 0 : decimals;
//         const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//         const i = Math.floor(Math.log(bytes) / Math.log(k));
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
//     }

//     const InputChange = (e) => {
//         // --For Multiple File Input

//         let images = [];
//         for (let i = 0; i < e.target.files?.length; i++) {
//             images.push((e.target.files[i]));
//             let reader = new FileReader();
//             let file = e.target.files[i];
//             console.log("fil file", file)
//             if (allowedTypes.includes(file.type)) {
//                 reader.onloadend = () => {
//                     setError(''); // Clear any previous errors
//                     setFormDataList(e.target.files[i])
//                     if (selectedfile?.length > 0) {
//                         SetSelectedFile((preValue) => {
//                             return [
//                                 ...preValue,
//                                 {
//                                     id: Math.random(),
//                                     filename: e.target.files[i].name,
//                                     filetype: e.target.files[i].type,
//                                     fileimage: reader.result,
//                                     datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
//                                     filesize: filesizes(e.target.files[i].size)
//                                 }
//                             ]
//                         });
//                     } else {
//                         SetSelectedFile((preValue) => {
//                             return [
//                                 {
//                                     id: Math.random(),
//                                     filename: e.target.files[i].name,
//                                     filetype: e.target.files[i].type,
//                                     fileimage: reader.result,
//                                     datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
//                                     filesize: filesizes(e.target.files[i].size)
//                                 }
//                             ]
//                         });
//                     }

//                 }
//                 if (e.target.files[i]) {
//                     reader.readAsDataURL(file);
//                 }

//             } else {
//                 setError('File type not supported. Please upload a JPEG, PNG file.');
//                 SetSelectedFile(null); // Clear file selection
//             }
//         }
//     }


//     const DeleteSelectFile = (id) => {
//         if (window.confirm("Are you sure you want to delete this Image?")) {
//             const result = selectedfile.filter((data) => data.id !== id);
//             SetSelectedFile(result);
//         } else {
//             // alert('No');
//         }

//     }

//     const FileUploadSubmit = async (e) => {
//         e?.preventDefault();
//         // form reset on submit 
//         // e.target.reset();
//         if (selectedfile?.length > 0) {
//             onUpload(formDataList)
//             for (let index = 0; index < selectedfile?.length; index++) {
//                 SetFiles((preValue) => {
//                     return [
//                         ...preValue,
//                         selectedfile[index]
//                     ]
//                 })
//             }
//             SetSelectedFile([]);
//         }
//         // else {
//         //     alert('Please select file')
//         // }

//     }


//     const DeleteFile = async (id) => {
//         if (window.confirm("Are you sure you want to delete this Image?")) {
//             const result = Files.filter((data) => data.id !== id);
//             SetFiles(result);
//         } else {
//             // alert('No');
//         }
//     }
//     useEffect(() => {
//         // setIsLoading(true)
//         FileUploadSubmit()
//     }, [selectedfile])
//     useEffect(() => {
//         SetFiles(productMasterImageDetails)
//         setIsLoading(false)
//     }, [productMasterImageDetails])

//     return (
//         !isLoading ?
//             <div className="fileupload-view">
//                 <div className="kb-data-box">
//                     <div className="kb-modal-data-title">
//                         {/* <div className="kb-data-title">
//                                         <h6>Multiple File Upload With Preview</h6>
//                                     </div> */}
//                     </div>
//                     <form enctype="multipart/form-data" id="fileupload">
//                         <div className="kb-file-upload">
//                             <div className="file-upload-box">
//                                 <input type="file" className="file-upload-input" onChange={InputChange} id="images" name="images[]" multiple />
//                                 <span>Drag and drop or <span className="file-link">Choose your files</span></span>
//                             </div>
//                             {error && <p style={{ color: 'red' }}>{error}</p>}
//                         </div>
//                         <div className="kb-attach-box mb-3">
//                             {
//                                 selectedfile?.map((data, index) => {
//                                     const { id, filename, filetype, fileimage, datetime, filesize } = data;
//                                     return (
//                                         <div className="file-atc-box" key={id}>
//                                             {
//                                                 filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
//                                                     <div className="file-image"> <img src={fileimage} alt="" /></div> :
//                                                     <div className="file-image"><i className="far fa-file-alt"></i></div>
//                                             }
//                                             <div className="file-detail">
//                                                 <h6>{filename}</h6>
//                                                 <p></p>
//                                                 <p><span>Size : {filesize}</span><span className="ml-2"> Modified Time : {datetime}</span></p>
//                                             </div>
//                                             <div className="file-actions">
//                                                 <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id)}><DeleteOutlineOutlinedIcon /> Delete</button>
//                                             </div>
//                                         </div>
//                                     )
//                                 })
//                             }
//                         </div>
//                         {/* <div className="kb-buttons-box">
//                             <button type="submit" className="btn btn-primary form-submit">Upload</button>
//                         </div> */}
//                     </form>
//                     {Files?.length > 0 ?
//                         <div className="kb-attach-box">
//                             <hr />
//                             {
//                                 Files?.map((data, index) => {
//                                     const { id, filename, filetype, fileimage, datetime, filesize } = data;
//                                     return (
//                                         <div className="file-atc-box" key={index}>
//                                             {
//                                                 filename?.match(/.(jpg|jpeg|png|gif|svg)$/i) || data ?
//                                                     <div className="file-image"> <img src={data} alt="" /></div> :
//                                                     <div className="file-image"><i className="far fa-file-alt"></i></div>
//                                             }
//                                             <div className="file-detail">
//                                                 {/* <h6>{filename}</h6>
//                                                 <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p> */}
//                                             </div>
//                                             <div className="file-actions">
//                                                 <button className="file-action-btn" onClick={() => DeleteFile(id)}><DeleteOutlineOutlinedIcon />Delete</button>
//                                                 <a href={fileimage} className="file-action-btn" download={filename}><FileDownloadOutlinedIcon />Download</a>
//                                             </div>
//                                         </div>
//                                     )
//                                 })
//                             }
//                         </div>
//                         : ''}
//                 </div>
//             </div> :
//             <LoadingComponent />
//     );
// }

// export default FileUpload
import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Axios is used for making HTTP requests

function FileUpload({ onUpload, imageLoading }) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');


    // Allowed file types
    const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/pdf',
        'text/plain',
        'application/zip',
        'application/x-rar-compressed',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'audio/mpeg',
        'audio/wav',
        'video/mp4',
        'video/x-msvideo',
        'video/x-matroska'
    ];

    // Handle file change
    const handleFileChange = (event) => {
        const files = event.target.files;
        const validFiles = [];

        for (const file of files) {
            if (allowedTypes.includes(file.type)) {
                validFiles.push(file);
            } else {
                setError(`File "${file.name}" type not supported.`);
                setSelectedFiles([]);
                return; // Exit the function if any file is not supported
            }
        }

        setSelectedFiles(validFiles);
        setError('');
        console.log("validFiles", validFiles)
        uploadFilesAsBinary(validFiles)
    };

    // Convert files to binary and send them
    const uploadFilesAsBinary = async (inputFiles) => {
        if (inputFiles.length === 0) {
            setError('Please select valid files to upload.');
            return;
        }

        try {
            const response = await onUpload(inputFiles)

        } catch (error) {
            console.error('Error uploading files:', error);
            setError('An error occurred while uploading files.');
        }
    }

    const DeleteFile = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = selectedFiles.filter((data) => data.id !== id);
            setSelectedFiles(result);
        } else {
            // alert('No');
        }
    }

    return (
        <div>
            <form enctype="multipart/form-data" id="fileupload">
                <div className="kb-file-upload">
                    <div className="file-upload-box">
                        <input type="file" className="file-upload-input" onChange={handleFileChange} id="images" name="images[]" multiple disabled={imageLoading} />
                        <span>Drag and drop or <span className="file-link">Choose your files</span></span>
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            </form>
            {/* {selectedFiles?.length > 0 ?
                <div className="kb-attach-box">
                    <hr />
                    {
                        selectedFiles?.map((data, index) => {
                            const { id, filename, filetype, fileimage, datetime, filesize } = data;
                            return (
                                <div className="file-atc-box" key={index}>
                                    {
                                        filename?.match(/.(jpg|jpeg|png|gif|svg)$/i) || data ?
                                            <div className="file-image"> <img src={data} alt="" /></div> :
                                            <div className="file-image"><i className="far fa-file-alt"></i></div>
                                    }
                                    <div className="file-detail">
                                        {/* <h6>{filename}</h6>
                                                <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                    </div>
                                    <div className="file-actions">
                                        <button className="file-action-btn" onClick={() => DeleteFile(id)}><DeleteOutlineOutlinedIcon />Delete</button>
                                        <a href={fileimage} className="file-action-btn" download={filename}><FileDownloadOutlinedIcon />Download</a>
                                    </div>
                                </div >
                            )
})
                    }
                </div >
                : ''} */}
            {/* <div>
                <h3>Selected Files:</h3>
                <ul>
                    {selectedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                    ))}
                </ul>
            </div> */}
        </div >
    );
}

export default FileUpload;

