import React, { createContext, useState, useContext, useEffect } from 'react';
import { __API_BASEURL__ } from '../constants/keyConstants';
import axios from 'axios';
import LoadingComponent from '../common/LoadingComponent/LoadingComponent';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const req = {
                'token': localStorage.getItem("token"),
            }
            axios.post(`${__API_BASEURL__}/create-bearer-token-from-redirect`, req, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': localStorage.getItem("token"),
                }
            }).then(function (res) {
                console.log("redirect", res)
                // setUser({ token });
            })
            axios.post(`${__API_BASEURL__}/create-bearer-token-from-redirect`, req, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': localStorage.getItem("token"),
                }
            }).then(function (res) {
                console.log("redirect", res)
                // setUser({ token });
            })
        }
        setLoading(false);
    }, []);

    const login = (token) => {
        localStorage.setItem('token', token);
        setUser({ token });
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    if (loading) {
        return <LoadingComponent />;
    }

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);