import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { __API_BASEURL__ } from '../../constants/keyConstants';
import { getRequest, postRequest } from '../../api/axiosConfig';

const initialState = {
    error: null,
};

export const fetchImageList = createAsyncThunk(
    'images/getImageList',
    async ({ endpointURL }) => {
        let newURL = `${endpointURL}`;
        const response = await getRequest(newURL);
        return response.data.result;
    })

export const linkDocuments = createAsyncThunk(
    'images/manageDocs',
    async ({ endpointURL, payload }) => {
        const response = await  postRequest(endpointURL, payload);
        return response;
    })

const imageSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        // builder.addCase(linkDocuments.pending, (state, action) => {

        // });
        // builder.addCase(linkDocuments.fulfilled, (state, action) => {

        // });
    },
});

// export const {  } = imageSlice.actions;

export default imageSlice.reducer;