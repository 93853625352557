import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react';
import TextEditor from '../../../components/TextEditor/TextEditor';
import CommentsComponent from './CommentsComponent';
import './Notes.scss';

function Notes({ notes, onNotesChange, onCommentsChange }) {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  let noteIdValue = localStorage.getItem("noteId")

  useEffect(() => {
    setCurrentNote(notes?.find((eachNote) => eachNote.id == noteIdValue))
  }, [notes, noteIdValue, isEditVisible])

  useEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      localStorage.removeItem("noteId")
    }
  }, [])

  return (
    <div className='pt-3'>

      {!isEditVisible ? <div className='ps-2 pt-2'>
        {notes?.map((eachNote) => <div className='row'>
          <div className="col-8 justify-content-start align-items-center">
            <p className='mb-1'><span className='text-muted'>Note by: </span>{eachNote.createdBy}
              {eachNote?.title && <span className='text-muted ps-3'>Subject: </span>} {eachNote.title}
              {eachNote?.comments && eachNote?.comments?.length > 0 && <span className='text-muted fs-12 ps-3'>{eachNote?.comments?.length} Comment(s) </span>}
            </p>
          </div>
          <div className="col-4">
            <div className='d-flex justify-content-end align-items-center'>
              <p className='fs-12 text-muted mb-0 pe-2'>{eachNote.createdAt}</p>
              {/* <IconButton><DeleteIcon fontSize='small' /></IconButton> */}
              <IconButton><EditIcon fontSize='small' onClick={() => {
                localStorage.setItem("noteId", eachNote?.id);
                setIsEditVisible(true)
              }} /></IconButton>
            </div>
          </div>
          <div className="col-12">
            {/* <p className='fs-14 mb-0'>{eachNote.note}</p> */}
            <Markup content={eachNote.note} />
          </div>
          <div className="col-12">
            <hr />
          </div>
        </div>)}

      </div> : <p style={{ position: 'relative' }}>
        <TextEditor markup="" onChangeCallback={onNotesChange} input={currentNote?.note} />
        <CommentsComponent onCommentsChange={onCommentsChange} inputData={currentNote?.comments} noteId={noteIdValue} />
      </p>}
    </div >
  );
}

export default Notes;
