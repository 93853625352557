import { Button, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

import tableConfig from '../../components/Table/tableConfig';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ITEM_HEIGHT, labelMap } from '../../constants/keyConstants';

import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GridViewIcon from '@mui/icons-material/GridView';

import LoadingComponent from '../../common/LoadingComponent/LoadingComponent';
import Board from '../../features/table/components/Board';
import { handleGridColumns, initializeColumnsInGrid, updatePagination } from '../../features/table/tableSlice';
import ListCheckbox from '../CustomCheckbox/ListCheckbox';
import SelectMenu from '../SelectMenu/SelectMenu';

import VisibilityToggleMenu from '../VisibilityToggleMenu/VisibilityToggleMenu';
import TablePagination from './TablePagination';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './TableWrapper.scss';
import { CloseOutlined } from '@mui/icons-material';


const TableWrapper = (props) => {
  const {
    tableTypeKey,
    apiList,
    handleClick,
    onChange,
    value,
    editAction,
    showSwimLane = false,
    actionHandlers,
    isMainTable = true
  } = props;

  const dispatch = useDispatch()


  const [headersList, setHeaderList] = useState(tableConfig(tableTypeKey))
  const [swimlaneDropdownValue, setSwimLaneDropdownValue] = useState("LifeCycle Stage")
  const [cardVisibilityList, setCardVisibilityList] = useState([])
  const { selectedIds,
    selectedColumnsInGrid,
    pagination,
    isLoading,
    leadDropdown,
    companyOwnerdropDownData
  } = useSelector(state => state.table)

  const [columnList, setColumnList] = useState(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId })))

  const colOptions = tableConfig(tableTypeKey)?.filter(eachHeaderItem => eachHeaderItem?.columnName !== null && eachHeaderItem?.columnName !== undefined)

  const [anchorEl, setAnchorEl] = useState(null);
  const [viewSelectionValue, setViewSelectionValue] = useState(0);
  const [showColumns, setShowColumns] = useState([]);
  const [hideColumns, setHideColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);


  // Function to handle drag and drop
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    // Reorder the columns based on drag and drop
    if (source.droppableId === destination.droppableId) {
      const reorderedColumns = Array.from(
        source.droppableId === 'show' ? showColumns : hideColumns
      );
      const [movedColumn] = reorderedColumns.splice(source.index, 1);
      reorderedColumns.splice(destination.index, 0, movedColumn);

      if (source.droppableId === 'show') {
        setShowColumns(reorderedColumns);
      } else {
        setHideColumns(reorderedColumns);
      }
    } else {
      const sourceColumns = source.droppableId === 'show' ? showColumns : hideColumns;
      const destinationColumns = destination.droppableId === 'show' ? showColumns : hideColumns;

      const [movedColumn] = sourceColumns.splice(source.index, 1);
      destinationColumns.splice(destination.index, 0, movedColumn);

      setShowColumns(destination.droppableId === 'show' ? destinationColumns : showColumns);
      setHideColumns(source.droppableId === 'hide' ? sourceColumns : hideColumns);
    }

  };

  // Function to finalize column selection
  const finalizeColumnSelection = () => {
    const updatedConfig = tableConfig(tableTypeKey)?.filter(col => showColumns.includes(col.columnName) || !col?.columnKey);
    setHeaderList(updatedConfig)
    console.log(updatedConfig);
    setShowModal(false);
  };

  // Initialize show and hide columns from table config
  useEffect(() => {
    const config = tableConfig(tableTypeKey);
    const shown = config.filter(col => col.columnKey).map(col => col.columnName);
    const hidden = config.filter(col => !col.isDraggable && col.columnKey).map(col => col.columnName);
    setShowColumns(shown);
    setHideColumns(hidden);
  }, [tableTypeKey]);



  const handleChange = (newValue) => {
    setViewSelectionValue(newValue);
    setColumnList(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStageName === owner?.value) })))
  };

  const open = Boolean(anchorEl);
  const disabled = selectedIds?.length === 0;


  const handleEditColClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, value) => {
    dispatch(updatePagination({
      perPage: pagination?.perPage,
      page: value
    }))
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    dispatch(updatePagination({
      perPage: newRowsPerPage,
      page: newRowsPerPage === pagination?.perPage ? pagination?.page : 1,
    }));
  };

  useEffect(() => {
    if (isMainTable) {
      dispatch(initializeColumnsInGrid({ tableType: tableTypeKey }));
    }
  }, [])

  // useEffect(() => {
  //   if (selectedColumnsInGrid && selectedColumnsInGrid?.length === 0) {
  //     setHeaderList(tableConfig(tableTypeKey))
  //   } else {
  //     // setHeaderList(tableConfig(tableTypeKey)?.filter(eachHeaderItem => selectedColumnsInGrid?.includes(eachHeaderItem?.columnName)))
  //   }
  // }, [tableTypeKey, apiList, selectedColumnsInGrid])


  const swimlaneDropdownList = [
    { label: "LifeCycle Stage", value: "LifeCycle Stage" },
    { label: "Company Owner", value: "Company Owner" },
    { label: "Lead Status", value: "Lead Status" }
  ];

  useEffect(() => {
    if (swimlaneDropdownValue === "LifeCycle Stage") {
      setColumnList(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStageName === owner?.value) })))
    }
    else if (swimlaneDropdownValue === "Lead Status") { setColumnList(leadDropdown && leadDropdown[1]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStatusName === owner?.value) }))) }
    else { setColumnList(companyOwnerdropDownData?.map((owner) => ({ ...owner, label: owner?.optionShow, value: owner?.employeeId, task: apiList?.filter((eachRow) => eachRow.contactOwnerName === owner?.name) }))) }
  }, [swimlaneDropdownValue, leadDropdown, companyOwnerdropDownData])



  const cardItems = Object.keys(labelMap);

  return (
    <div className="row">
      {isLoading ? <LoadingComponent /> : <>
        <div className="col-12 col-lg-4 d-flex">
          {showSwimLane && <IconButton onClick={() => handleChange(0)} className={viewSelectionValue === 0 ? 'selected-button' : 'unselected-button'} title='Grid View'><GridViewIcon fontSize='small' /> </IconButton>}
          {showSwimLane && <IconButton onClick={() => handleChange(1)} className={viewSelectionValue === 1 ? 'selected-button' : 'unselected-button'} title='Swimlane View'><CalendarViewWeekIcon fontSize='small' /> </IconButton>}
          <p className='mt-3 ml-3 fs-14'>Total : <span className=' fw-bold'>{pagination?.totalRecords || apiList.length}</span> {selectedIds?.length > 0 && <i className=''>Selected : <span className='fw-bold text-primary'>{selectedIds?.length}</span></i>}</p>
        </div>
        <div className='col-12 col-lg-8 d-flex align-items-end justify-content-end' >
          {viewSelectionValue === 0 && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {apiList?.length > 0 && selectedIds?.length === apiList?.length && <Button label="" variant="text">Email All</Button>}
              <Button variant='text' disabled={disabled}><DeleteOutlinedIcon fontSize='small' />Delete</Button>
              <Button variant='text' disabled={disabled}><EditOutlinedIcon fontSize='small' />Edit</Button>
              <Button variant='text' disabled={disabled}><ExitToAppOutlinedIcon fontSize='small' />Export</Button>
              {/* <Button className=''
                aria-label="more"
                id="select-col-button"
                aria-controls={open ? 'colName' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleEditColClick}
              >
                Edit Columns <MoreVertIcon />
              </Button>
              <Menu
                id="colName"
                MenuListProps={{
                  'aria-labelledby': 'select-col-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                {colOptions.map((colOptions) => (

                  <MenuItem key={colOptions.columnKey} >
                    <ListCheckbox
                      onChange={(event) => dispatch(handleGridColumns({
                        isChecked: event.target.checked,
                        selectedColumnName: colOptions?.columnName
                      }))}
                      checked={selectedColumnsInGrid?.includes(colOptions?.columnName)}
                      label={colOptions?.columnName}
                    />
                  </MenuItem>
                ))}
              </Menu> */}
              {/* <Button onClick={handleEditColClick}>Edit Columns</Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="show">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <h4>To Show</h4>
                  {showColumns.map((col, index) => (
                    <Draggable key={col} draggableId={col} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {col}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId="hide">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <h4>Not To Show</h4>
                  {hideColumns.map((col, index) => (
                    <Draggable key={col} draggableId={col} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {col}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button onClick={finalizeColumnSelection}>Confirm Selection</Button>
        </div>
      </Menu> */}
              <Button className="btn btn-primary text-white" onClick={() => setShowModal(true)}>Edit Columns</Button>

              <div className={`modal ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Columns</h5>
                      <IconButton onClick={() => setShowModal(false)}
                        title='Close'><CloseOutlined fontSize='small' />
                      </IconButton>
                    </div>
                    <div className="modal-body">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <div className="row">
                          <div className="col">
                            <h4>Visible Columns</h4>
                            <Droppable droppableId="show">
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="border p-2">
                                  {showColumns.map((col, index) => (
                                    <Draggable key={col} draggableId={col} index={index}>
                                      {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="p-2 border-bottom">
                                          {col}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                          <div className="col">
                            <h4>Hidden Columns</h4>
                            <Droppable droppableId="hide">
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="border p-2">
                                  {hideColumns.map((col, index) => (
                                    <Draggable key={col} draggableId={col} index={index}>
                                      {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="p-2 border-bottom">
                                          {col}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </div>
                      </DragDropContext>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                      <button type="button" className="btn btn-primary" onClick={() => {
                        finalizeColumnSelection()
                      }}>Confirm Selection</button>
                    </div>
                  </div>
                </div>
              </div>
            </Stack>
          )}
          {viewSelectionValue === 1 && (
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="flex-end">
              <div className='filterSelect'>
                <SelectMenu
                  // selectLabel="Filter "
                  value={swimlaneDropdownValue}
                  options={swimlaneDropdownList}
                  onChangeCallback={(selectedValue) => setSwimLaneDropdownValue(selectedValue)}
                />
              </div>
              <div>
                <VisibilityToggleMenu items={cardItems} setCardVisibilityList={setCardVisibilityList} />
              </div>

            </Stack>
          )}
        </div>
        {/* <div className='col-12 col-md-6 col-lg-3 pt-1'>
          <Tabs className='viewOptionTabs'
            onChange={handleChange}
            value={viewSelectionValue}
            aria-label="Tabs where selection follows focus"
            selectionFollowsFocus
          >
            <Tab icon={<GridViewIcon fontSize='small' />} iconPosition='start' label="Grid View"  {...a11yProps(0)} />
            {showSwimLane && <Tab icon={<CalendarViewWeekIcon fontSize='small' />} iconPosition='start' label="Swimlane View" {...a11yProps(1)} />}
          </Tabs>
        </div>
        <div className='col-12 col-md-6 col-lg-9 mt-md-4 text-lg-end'>
          {gridData?.length > 0 && selectedIds?.length === gridData?.length && <Button label="" variant="outlined">Email All</Button>}

        </div>
        <div className='col-12 pt-3'>
          <CustomTabPanel value={viewSelectionValue} index={0} >
            <div className='tableContainer '>
              <table className="table table-hover ">
                <TableHeader
                  headers={headersList}
                  records={apiList}
                  onChange={onChange}
                />
                <TableBody
                  headers={headersList}
                  records={apiList}
                  handleClick={handleClick}
                  onChange={onChange}
                  value={value}
                  editAction={editAction}
                />
              </table>
              <div className="col-12">
                <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                  <p className='m-0 fs-14'>Showing <span className='fw-bold'>{gridData?.length} / {pagination?.totalRecords}</span> Records</p>
                  <Pagination
                    count={pagination?.count}
                    page={pagination?.page}
                    onChange={handlePageChange}
                    color="primary"
                  />

                </Stack>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={viewSelectionValue} index={1}>
            <Board columnList={columnList} taskList={cardVisibilityList} />
          </CustomTabPanel>
        </div> */}
        <div className='col-12'>
          {viewSelectionValue === 0 && (
            <div className='tableContainer '>
              <table className="table table-hover ">
                <TableHeader
                  headers={headersList}
                  records={apiList}
                  onChange={onChange}
                />
                <TableBody
                  headers={headersList}
                  records={apiList}
                  handleClick={handleClick}
                  onChange={onChange}
                  value={value}
                  editAction={editAction}
                  actionHandlers={actionHandlers}
                />
              </table>
              <div className="col-12">
                <TablePagination
                  pagination={pagination}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  length={apiList?.length}
                />
              </div>
            </div>
          )}
          {viewSelectionValue === 1 && (
            <div><Board columnList={columnList} taskList={cardVisibilityList} /></div>
          )}
        </div>
      </>}
    </div>
  );
};
export default TableWrapper;
