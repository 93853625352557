import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import "./UploadImageDialog.scss";
import { useDispatch } from "react-redux";
import { __API_BASEURL__ } from "../../constants/keyConstants";
import axios from "axios";
import Notifications from "../Notifications/Notifications";
import LoadingComponent from "../../common/LoadingComponent/LoadingComponent";
import { fetchImageList, linkDocuments } from "../../features/FileUpload/imageSlice";
import FileUploadWrapper from "../../features/FileUpload/FileUploadWrapper";
import { useNavigate } from "react-router-dom";

const UploadImageDialog = ({ open, onClose, linkObject, setIsDocumentLinked }) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();
  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "success",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const dialogContentRef = useRef();
  const navigate = useNavigate();

  const lastImageElementRef = useCallback(
    (node) => {
      if (imageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [imageLoading, hasMore]
  );
  const onImageLinking = () => {
    const request = {
      companyId: company_id,
      userId: user_id,
      objectName: Object.keys(linkObject)[0],
      objectId: Object.values(linkObject)[0],
      documentIds: selectedFiles
    }
    dispatch(linkDocuments({ endpointURL: '/master/library/link', payload: request }))?.then((res) => {
      console.log("res.payload?.data?.message", res.payload?.data?.message)
      setNotification({
        open: true,
        message: res.payload?.data?.message,
        color: "success",
        confirmationHandler: () => {
          onClose();
          setSelectedFiles([])
          setIsDocumentLinked(true)
          // navigate(`/worx/projects/view/${Object.values(linkObject)[0]}?Photos`)
        },
      });

    })
  }

  const downloadFile = (file) => {
    axios({
      url: `${__API_BASEURL__}/master/library/download?companyId=${company_id}&userId=${user_id}&fileId=${file?.id}`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Network response was not ok');
        }
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
      })
      .catch((error) => {
        console.error('There was an error downloading the file:', error);
        setNotification({
          open: true,
          message: 'Error downloading file',
          color: "error",
        });
      });
  };

  useEffect(() => {
    setPage(1);
    setImageList([]);
    setHasMore(true);
  }, [searchTerm]);

  useEffect(() => {
    if (open) {
      fetchImages();
    }
  }, [searchTerm, page, open]);

  const fetchImages = () => {
    setImageLoading(true);
    dispatch(
      fetchImageList({
        endpointURL: `/master/library/list?company_id=${company_id}&user_id=${user_id}&search=${searchTerm}&take=25&page=${page}`,
      })
    ).then((res) => {
      const newImages = res?.payload?.list || [];
      setImageList((prevImages) =>
        page === 1 ? newImages : [...prevImages, ...newImages]
      );
      setHasMore(newImages.length === 25);
      setImageLoading(false);
    });
  };

  const onUpload = (formDataList) => {
    setImageLoading(true);
    const formData = new FormData();

    formDataList.forEach((file) => {
      formData.append("images[]", file);
    });
    formData.append("companyId", company_id);
    formData.append("userId", user_id);
    formData.append("objectName", "library");

    axios
      .post(`${__API_BASEURL__}/module/uploads/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (res) {
        setPage(1);
        fetchImages();
      })
      .catch(function (error) {
        setImageLoading(false);
        setNotification({
          open: true,
          message: `Something went wrong while adding files`,
          color: "error",
        });
      });
  };

  const onDelete = (idList = [], name) => {
    setNotification({
      open: true,
      message: `Do you really want to delete ${name}?`,
      color: "warning",
      confirmationHandler: () => deleteHandler(idList),
    });
  };

  const deleteHandler = (ids) => {
    setImageLoading(true);
    const deleteRequest = {
      companyId: company_id,
      userId: user_id,
      fileIds: ids,
    };

    axios
      .post(`${__API_BASEURL__}/master/library/delete`, deleteRequest, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (res) {
        if (res.data.success) {
          setImageLoading(false);
          setNotification({
            open: true,
            message: res?.data?.message,
            color: "success",
          });
          setPage(1);
          fetchImages();
        }
      })
      .catch(function (error) {
        setImageLoading(false);
        setNotification({
          open: true,
          message: `Something went wrong while deleting files`,
          color: "error",
        });
      });
  };

  const onCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      className="uploadImageDialog"
    >
      {notification?.open && (
        <Notifications {...notification} onClose={onCloseNotification} showCancelButton={true} />
      )}
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        X Drive
      </DialogTitle>
      <div className="imageSearchBox">
        <TextField
          variant="outlined"
          placeholder="Search for images"
          size="small"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end"><CloseIcon onClick={() => setSearchTerm("")} className="cursor-pointer" /></InputAdornment>,
          }}
        />
      </div>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 12,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers ref={dialogContentRef}>
        {imageList && imageList.length > 0 && (
          <FileUploadWrapper
            imageDetails={imageList}
            onUpload={onUpload}
            imageLoading={false}
            onDelete={onDelete}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            lastImageElementRef={lastImageElementRef}
            downloadFile={downloadFile}
          />
        )}
        {imageLoading && <LoadingComponent height="5vh" />}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => {
          onClose();
          setSelectedFiles([])
        }} variant="outlined">
          Cancel
        </Button>
        <Button autoFocus onClick={() => {
          onImageLinking()
        }} variant="contained">
          Use {`${selectedFiles?.length}`} selected Image(s)
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImageDialog;