import React, { useCallback, useEffect, useState } from "react";

import "./ServicesList.scss";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../../components/Table/TableWrapper";
import { __API_BASEURL__, SERVICE_LIST } from "../../../../constants/keyConstants";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactList, fetchProductMasterDetails, handleDataSelection } from '../../../../features/table/tableSlice';
import Search from "../../../../components/Search/Search";
import Notifications from '../../../../components/Notifications/Notifications';
import axios from 'axios'
import { debounce } from "lodash";

export default function ServicesList() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { gridData, pagination } = useSelector(state => state.table)
  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    color: "success",
  });
  const [searchText, setSearchText] = useState('')

  function handleDebounce(inputValue) {
    dispatch(fetchContactList({
      endpointURL: `/master/product/list?company_id=${company_id}&user_id=${user_id}&type=service`,
      searchValue: inputValue,
    }))
  }
  const debounceFn = useCallback(debounce(handleDebounce, 500), []);

  useEffect(() => {
    debounceFn(searchText)
  }, [searchText])

  const addProductBtnClick = () => {
    navigate("/masters/services/add");
  };


  const onChange = (event, recordId) => {
    if (recordId !== undefined) {
      dispatch(
        handleDataSelection({
          selectedRecordId: recordId,
          allSelected: false,
          actionType: event.target.checked
        })
      );
    } else {
      dispatch(
        handleDataSelection({
          allSelected: true,
          actionType: event.target.checked
        })
      );
    }
  }

  useEffect(() => {
    dispatch(fetchContactList({ endpointURL: `/master/product/list?company_id=${company_id}&user_id=${user_id}&page=${pagination?.page}&take=${pagination?.perPage}&type=service` }))
    if (localStorage.getItem("service_currentTab")) {
      localStorage.removeItem("service_currentTab")
    }
  }, [pagination?.page, pagination?.perPage])


  const rowDeleteHandler = (productId) => {
    const productName = gridData?.find((eachrecord) => eachrecord?.id == productId)?.name
    setNotification({
      open: true,
      message: `Do you really want to delete ${productName}?`,
      color: "warning",
      confirmationHandler: () => deleteRow(productId)
    })
  }
  const deleteRow = (productId) => {
    axios.post(`${__API_BASEURL__}/master/product/delete?company_id=${company_id}&user_id=${user_id}&productId=${productId}`, null, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      }
    }).then(function (res) {
      if (res.data.status == 200) {
        dispatch(fetchContactList({ endpointURL: `/master/product/list?company_id=${company_id}&user_id=${user_id}&page=${pagination?.page}&take=${pagination?.perPage}&type=service` }))
        setNotification({
          open: true,
          message: res.data.message,
          color: "success",
        })
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went wrong while deleting. Please try again!',
          color: "error",
        })
      })
  }

  const rowCloneHandler = (productId) => {
    const productName = gridData?.find((eachrecord) => eachrecord?.id == productId)?.name
    setNotification({
      open: true,
      message: `Do you really want to clone ${productName}?`,
      color: "warning",
      confirmationHandler: () => cloneRow(productId)
    })
  }
  const cloneRow = (productId) => {
    axios.post(`${__API_BASEURL__}/master/product/clone?company_id=${company_id}&user_id=${user_id}&productId=${productId}`, null, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      }
    }).then(function (res) {
      if (res.data.status == 200) {
        setTimeout(() => {
          if (res.data.result.product_id) {
            dispatch(fetchProductMasterDetails(`/master/product/details?company_id=${company_id}&user_id=${user_id}&product_id=${res.data.result.product_id}&type=service`))
            navigate(`/masters/services/edit/${res.data.result.product_id}`);
          }
        }, 1000)
        setNotification({
          open: true,
          message: res.data.message,
          color: "success",
        })
      }
    })
      .catch(function (error) {
        setNotification({
          open: true,
          message: 'Something went wrong while deleting. Please try again!',
          color: "error",
        })
      })
  }

  const onCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }

  const onSearchChangeHander = (event) => {
    setSearchText(event.target.value)

  }
  const onSearchClearAllHander = () => {
    setSearchText('')
  }

  return (
    <div className="container-fluid">
      {notification?.open && <Notifications
        {...notification}
        onClose={onCloseNotification}
        showCancelButton={true} />}
      <div className="white-container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" gap={4}>
              <h1 className="pageHead mb-0">Service List</h1>
              <Search placeholder="Enter to Search services"
                onChange={onSearchChangeHander}
                onClearAll={onSearchClearAllHander}
                searchText={searchText}
              />
            </Stack>

            {/* <p className="m-0 pe-3">All products listed here</p> */}
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-center justify-content-end  pt-2 ">
            {/* <p className="m-0 pe-3">If not found</p> */}
            <Button variant="contained" onClick={addProductBtnClick}>
              Add Service
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-2">
              <TableWrapper
                tableTypeKey={SERVICE_LIST}
                onChange={onChange}
                apiList={gridData}
                actionHandlers={{
                  rowDeleteHandler,
                  rowCloneHandler
                }}
              // editAction={{
              //   enableEditingMode,
              //   enableEdit: editCell,
              // }}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
