import { configureStore } from '@reduxjs/toolkit';
import dealReducer from './../features/table/dealSlice';
import pipelineReducer from './../features/table/pipelineSlice';
import tableReducer from './../features/table/tableSlice';
import taskReducer from './../features/table/taskSlice';
export const store = configureStore({
    reducer: {
        table : tableReducer,
        task: taskReducer,
        pipeline: pipelineReducer,
        deal:dealReducer
    },
})