import React, { useState } from 'react';
import { Pagination, MenuItem, Select, FormControl, InputLabel, TextField, Typography } from '@mui/material';

const TablePagination = ({ pagination, handlePageChange, handleRowsPerPageChange, length }) => {
  const page = pagination?.page || 1;
  const totalRecords = pagination?.totalRecords || 0;
  const perPage = pagination?.perPage || 1;
  const [rowsPerPage, setRowsPerPage] = useState(pagination?.perPage || 10); // Default rows per page
  const handleSelectChange = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    handleRowsPerPageChange(newRowsPerPage); // Call the handler to update rows per page
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <FormControl variant="outlined" size="small" className='d-flex flex-row gap-2'  >
        <Typography className='fs-14' >Rows per page:</Typography>
        <Select variant='standard' className='fs-14 fw-bold'
          labelId="rows-per-page-label"
          value={rowsPerPage} 
          onChange={handleSelectChange}
          label="Rows per page"
        >
          {[5, 10, 15, 25, 50].map((num) => (
            <MenuItem key={num} value={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <p className='m-0 fs-14'>Showing <span className='fw-bold'>{length} Records of Page {page} {totalRecords ? ` (${perPage * (page - 1) + 1} - ${(perPage * page) > totalRecords ? totalRecords : perPage * page})` : null}</span></p>

      <Pagination
        count={pagination?.count}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
    </div>
  );
};

export default TablePagination;
