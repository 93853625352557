import './App.scss';
import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import routes from './constants/routeConfig'
import LoadingComponent from './common/LoadingComponent/LoadingComponent';
import { AuthProvider } from './auth/AuthContext';
import PrivateRoute from './auth/PrivateRoute';
import NotFound from './pages/NotFound';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import AuthenticatedLayout from './auth/AuthenticatedLayout';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="App">
          <Suspense fallback={<LoadingComponent />}>
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/404" element={<NotFound />} />

              {/* Private routes */}
              <Route element={<PrivateRoute />}>
                <Route element={<AuthenticatedLayout />}>
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.Component />}
                    />
                  ))}
                </Route>
              </Route>

              {/* Redirect to 404 for undefined routes */}
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;