
import React, { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './AutocompleteSelect.scss';
const AutocompleteSelect = ({ error,helperText,value, options, label, onChange, onClick, renderOption, disabled, onInputChange, getOptionSelected,required  }) => {
  const [option, setOption] = useState(options);
  return (
    <Autocomplete
      value={value || null}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => <TextField {...params}
        disabled={disabled}
        label={label} variant='standard'
        fullWidth
        onChange={onInputChange}
        error={error}
        helperText={helperText}
      />}
      renderOption={renderOption}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      selectOnFocus
      clearOnBlur
      disableClearable
      handleHomeEndKeys
      required={required}
    />
  );
};

export default AutocompleteSelect;
