import React, { useEffect, useState } from 'react';
import LoadingComponent from '../../common/LoadingComponent/LoadingComponent';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchImageList, linkDocuments } from '../../features/FileUpload/imageSlice';
import { DeleteOutline as DeleteIcon } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import './PhotosArea.scss';
import { isValidImage } from '../../common/utils';
import axios from 'axios';
import { __API_BASEURL__ } from '../../constants/keyConstants';

const PhotosArea = (props) => {
    const { onClickHandler, id, setNotification = () => { },
        showAddButton = true,
        fullWidth = false,
        objectName = 'project',
        isDocumentLinked = false,
        setIsDocumentLinked
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const company_id = localStorage.getItem("company_id");
    const user_id = localStorage.getItem("user_id");
    const [imageLoading, setImageLoading] = useState(false);
    const [docLinkId, setDocLinkId] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        setImageLoading(true);
        dispatch(
            fetchImageList({
                endpointURL: `/master/library/link-list?company_id=${company_id}&user_id=${user_id}&objectName=${objectName}&objectId=${id}`,
            })
        ).then((res) => {
            setImageLoading(false);
            if (res.payload?.docLinkId) {
                setDocLinkId(res.payload?.docLinkId);
            }
            if (res.payload?.docs) {
                setFiles(res.payload?.docs);
                setImageLoading(false);
            }
        });
    }, [isDocumentLinked]);

    const delinkImage = (fileId) => {
        const request = {
            companyId: company_id,
            userId: user_id,
            docLinkId,
            objectId: id,
            fileId
        };
        dispatch(linkDocuments({ endpointURL: '/master/library/dlink', payload: request }))?.then((res) => {
            setImageLoading(true);
            const redirectURl = objectName === 'project' ? `/worx/projects/view/${id}?Photos` : null;
            if (res.payload?.data?.success) {
                setImageLoading(false);
                setNotification({
                    open: true,
                    message: res.payload?.data?.message,
                    color: "success",
                    confirmationHandler: () => {
                        redirectURl ? navigate(redirectURl) : setIsDocumentLinked(!isDocumentLinked);
                    },
                });
            }
            if (!res.payload) {
                setImageLoading(false);
                setNotification({
                    open: true,
                    message: 'Something Went Wrong while delinking...',
                    color: "error",
                });
            }
        }).catch((error) => {
            setImageLoading(false);
            setNotification({
                open: true,
                message: 'Something Went Wrong while delinking...',
                color: "error",
            });
        });
    };

    const downloadFile = (file) => {
        axios({
            url: `${__API_BASEURL__}/master/library/download?companyId=${company_id}&userId=${user_id}&fileId=${file?.id}`,
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
            }
        })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error('Network response was not ok');
                }
                const url = window.URL.createObjectURL(new Blob([res?.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setTimeout(() => window.URL.revokeObjectURL(url), 100);
            })
            .catch((error) => {
                console.error('There was an error downloading the file:', error);
                setNotification({
                    open: true,
                    message: 'Error downloading file',
                    color: "error",
                });
            });
    };

    const checkPreviewFile = (file) => {
        return (
            <div>
                {isValidImage(file.fileName) ? (
                    <img src={file.url} alt={file.fileName} />
                ) : (
                    <SdCardAlertIcon />
                )}
            </div>
        );
    };

    const renderFileItem = (file, index) => {
        return (
            <div>
                <div className={"gridBox"}>
                    <Stack direction="row" alignItems="center" gap={0}>
                        <FormControlLabel control={<Checkbox />} />
                        <Typography className="imgName" title={file.fileName}>
                            {file.fileName}
                        </Typography>
                    </Stack>
                    <div className="imgHolder">
                        <div className="file-atc-box">
                            <div className="file-image">
                                {/* <img src={file?.url} alt={file?.url} /> */}
                                {checkPreviewFile(file)}
                            </div>
                        </div>
                    </div>
                    <Stack
                        direction="row"
                        gap={1}
                        justifyContent="flex-end"
                        paddingRight={1}
                    >
                        <IconButton edge="end" aria-label="download" onClick={() => downloadFile(file)}>
                            <DownloadIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => delinkImage(file?.id)}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </div>
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <div className="white-container-auto mb-3">
                {showAddButton && (
                    <div className="upload-area border-1 border-dotted-primary d-flex align-items-center justify-content-center gap-2 text-blue" onClick={onClickHandler}>
                        <AddPhotoIcon /> <h5 className='mb-0'>Add Photos</h5>
                    </div>
                )}
                {imageLoading ? (
                    <LoadingComponent />
                ) : (
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {files && files.length > 0 ? (
                                files.map((file, index) => (
                                    <Grid item xs={6} sm={3} md={2} key={file.id}>
                                        {renderFileItem(file, index)}
                                    </Grid>
                                ))
                            ) : (
                                <p className='d-flex p-5 w-100 border border-1 m-1 mx-3'>Add files to Preview here</p>
                            )}
                        </Grid>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default PhotosArea;
