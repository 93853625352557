import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MaskedInput from "react-text-mask";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";

const PhoneMask = React.forwardRef((props, ref) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
});

// Custom styled Select without borders
const CountryCodeSelect = styled(Select)({
  "&.MuiInputBase-root": {
    border: "none",
    paddingRight: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    padding: 0,
  },
});

const PhoneTextField = ({ defaultValue, readOnly, countryCode, ...other }) => {
  return (
    <TextField
      label="Phone Number"
      fullWidth
      defaultValue={defaultValue}
      variant="standard"
      InputProps={{
        inputComponent: PhoneMask,
        readOnly: readOnly !== undefined ? readOnly : false,
        startAdornment: (
          <InputAdornment position="start">
            <CountryCodeSelect
              value={countryCode}
              onChange={other.onCountryCodeChange}
              displayEmpty
              variant="standard"
              disableUnderline
            >
              <MenuItem value="+1">+1</MenuItem>
              <MenuItem value="+91">+91</MenuItem>
              <MenuItem value="+44">+44</MenuItem>
            </CountryCodeSelect>
          </InputAdornment>
        ),
      }}
      InputLabelProps={defaultValue || readOnly ? { shrink: true } : {}}
      {...other}
    />
  );
};

const PhoneNumberWithCountryCode = () => {
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  return (
    <PhoneTextField
      defaultValue={phoneNumber}
      countryCode={countryCode}
      onCountryCodeChange={handleCountryCodeChange}
      onChange={(e) => setPhoneNumber(e.target.value)}
    />
  );
};

export default PhoneNumberWithCountryCode;
