import { validImageExtensionsToPreview } from '../constants/keyConstants';

//  TODO : to clip long text based on length 
export const clipLongText =
    (inputText, limit) => {
        return inputText?.length > limit ? `${inputText.substring(0, limit)}...` : inputText;
    }

// Estimate calculations
export const calculateTotal = (qty = 1, sales, discountType = "%", discountValue) => {
    const quantity = parseInt(qty)
    let total = isNaN(quantity * sales) ? 0 : quantity * sales;
    console.log("inside calculateTotal : total", total, "sales", sales, "qty", qty, "discountType", discountType)
    if (discountValue > 0) {
        total = discountType !== "%" ? (total - discountValue) : (total - ((total / 100) * discountValue))
    }
    console.log("inside calculateTotal : total before return", total)
    return total;
}
export const calculateDiscount = (total, discountValue, discountType) => {
    const calculatedDiscount = discountType !== "%" ? parseFloat(discountValue) : ((total / 100) * discountValue)
    return isNaN(calculatedDiscount) ? 0 : calculatedDiscount;
}

export const calculateGP = (sales_price, cost_price) => {
    const GP = sales_price - cost_price;
    const formattedVal = isNaN(GP) ? 0 : GP.toFixed(2)
    return formattedVal;
}

export const calculateMarginPrice = (cost, margin) => {
    if (margin == 100) {
        margin = 99.999;
    }
    const sales_price = (parseInt(cost) / (100 - parseInt(margin))) * 100;
    const formattedVal = isNaN(sales_price) ? 0 : sales_price.toFixed(2)
    return formattedVal;
}

export const calculateMarkupPrice = (cost, markup) => {
    const sales_price = parseInt(cost) * (100 + parseInt(markup)) / 100;
    const formattedVal = isNaN(sales_price) ? 0 : sales_price.toFixed(2)
    return formattedVal;
}


export const calculateMargin = (cost, sales) => {
    if (sales == 0) {
        return 0;
    }
    const margin = 100 - ((cost * 100) / sales);
    const formattedVal = isNaN(margin) ? 0 : margin.toFixed(2)
    return formattedVal;
}

export const parseToNumber = (inputVal) => {
    return typeof inputVal == "string" ? isNaN(parseInt(inputVal)) ? "" : parseInt(inputVal) : inputVal
}

export const addDecimalPadding = (value) => {
    const checkForDecimals = value?.includes(".") ? value : parseInt(value)?.toFixed(2)
    return checkForDecimals;
}

export const isValidImage = (fileName) => {
    const extension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
    return validImageExtensionsToPreview.includes(extension);
  };